function upPage() {
	window.addEventListener('scroll', () => {
		if (window.pageYOffset >= 800) {
			document.querySelector('.button-up').classList.add('show-button-up')
		} else {
			document.querySelector('.button-up').classList.remove('show-button-up')
		}
	})

	document.querySelector('.button-up').addEventListener('click', () => {
		window.scrollTo(0,0)
	})
	
}
export default upPage