// import { closeModal } from './modal.js'


// function formEmail(selectorForm) {
// 	const form = document.querySelector(selectorForm)
// 	const title = document.querySelector('.modal-wrapper')
// 	const button = document.querySelector('.modal-form__button')

// 	form.addEventListener('submit', formSend)

// 	async function formSend(e) {
// 		e.preventDefault()

// 		let error = formValidate(form)
// 		let formData = new FormData(form)

// 		if (error === 0) {
// 			form.classList.add('_sending')
// 			let response = await fetch('../../sendmail.php', {
// 				method: 'POST',
// 				body: formData
// 			})
// 			console.log(formData)
// 			if (response.ok) {
// 				let result = await response.json()
// 				form.reset()
// 				title.insertAdjacentHTML('beforeend', '<span class="tell-error">We will contact you soon</span>')
// 				form.classList.remove('_sending')
// 				button.disabled = true
// 				button.style.cssText = `
// 					opacity: .1;
// 				`
// 				const tellError = document.querySelector('.modal-wrapper span')
// 				setTimeout(() => tellError.remove(), 2000)
// 				setTimeout(() => closeModal('.modal'), 4000)
// 			} else {
// 				title.insertAdjacentHTML('beforeend', '<span class="tell-error">Ошибка отправки формы</span>')
// 				form.classList.remove('_sending')
// 				button.disabled = true
// 				button.style.cssText = `
// 					opacity: .1;
// 				`
// 				const tellError = document.querySelector('.modal-wrapper span')
// 				setTimeout(() => tellError.remove(), 2000)
// 				setTimeout(() => closeModal('.modal'), 4000)
// 			}

// 		} else {
// 			title.insertAdjacentHTML('beforeend', '<span class="tell-error">Заполните обязательные поля</span>')
// 			const tellError = document.querySelector('.modal-wrapper span')
// 			setTimeout(() => tellError.remove(), 2000)


// 		}
// 	}


// 	function formValidate(form) {
// 		let error = 0
// 		let formReq = document.querySelectorAll('._req')

// 		for (let i = 0; i < formReq.length; i++) {
// 			const input = formReq[i]
// 			formRemoveError(input)

// 			if (input.classList.contains('_email')) {
// 				if (emailTest(input)) {
// 					formAddError(input)
// 					error++
// 				}
// 			} else {
// 				if (input.value === '') {
// 					formAddError(input)
// 					error++
// 				}
// 			}
// 		}
// 		return error
// 	}

// 	function formAddError(input) {
// 		input.classList.add('_error')
// 	}
// 	function formRemoveError(input) {
// 		input.classList.remove('_error')
// 	}
// 	//проверяет поле емаил на наличие символов
// 	function emailTest(input) {
// 		return !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(input.value)
// 	}


// } export default formEmail



const forms = () => {
	const form = document.querySelectorAll('form'),
		inputs = document.querySelectorAll('input')

	const messages = {
		loading: 'Loading...',
		success: 'Thanks',
		failure: 'Error...'
	}


	const postData = async (url, data) => {
		document.querySelector('.status').innerHTML = messages.loading

		let result = await fetch(url, {
			method: 'POST',
			body: data
		})

		return await result.text()
		
	}


	const clearInputs = () => {
		inputs.forEach( item => {
			item.value = ''
		})
	}

	form.forEach(item => {
		item.addEventListener('submit', (e) => {
			e.preventDefault()


			let statusMessage = document.createElement('div')
			statusMessage.classList.add('status')
			item.appendChild(statusMessage)

			const formData = new FormData(item)
			
			postData('../../sendmail.php', formData)
				.then(result => {
					console.log(result)
					
					statusMessage.textContent = messages.success
				})
				.catch( () => {
					statusMessage.textContent = messages.failure
				})
				.finally(() => {
					clearInputs()
					setTimeout(() => {
						statusMessage.remove()
					}, 5000)
				})

		})
	})
}

export default forms