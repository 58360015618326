import burgerMenu from './js/components/burgerMenu'
import slider from './js/components/slider'
import forms from './js/components/form'
import upPage from './js/components/buttonUp'
import modal from './js/components/modal'
import {
	openModal
} from './js/components/modal'



document.addEventListener("DOMContentLoaded", function (e) {

	const modalTimerId = setTimeout(() => openModal(".modal", modalTimerId), 50000000)

	// const href = window.top.location.href
	// console.log(href)
	modal("[data-modal]", ".modal", modalTimerId)
	forms()
	upPage()
	burgerMenu(e)
	slider({
		container: '.second-screen__colection-slide',
		slide: '.second-screen__colection-slide',
		nextArrow: '.second-screen__block-next',
		prevArrow: '.second-screen__block-prev',
		totalCounter: '#total',
		currentCounter: '#current',
		wrapper: '.second-screen__colection',
		field: '.second-screen__colection-show',
		autoPlay: 8000
	})



// facebook 
	var chatbox = document.getElementById('fb-customer-chat');
		chatbox.setAttribute("page_id", "108815814760759");
		chatbox.setAttribute("attribution", "biz_inbox");
		window.fbAsyncInit = function () {
			FB.init({
				xfbml: true,
				version: 'v11.0'
			});
		};
		(function (d, s, id) {
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) return;
			js = d.createElement(s); js.id = id;
			js.src = 'https://connect.facebook.net/ru_RU/sdk/xfbml.customerchat.js';
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'facebook-jssdk'));

})