function burgerMenu(e) {

	let burger = document.querySelector('.burger-menu__button')
	let body = document.querySelector('body')
	let travel = document.querySelector('.burger-submenu__pmg-button')
	let room = document.querySelector('.burger-submenu__grazhdanstvo-button')
	let travelSub = document.querySelector('.burger-submenu__pmg')
	let roomSub = document.querySelector('.burger-submenu__grazhdanstvo')
	let showMenu = document.querySelector('.burger-menu__content-links')
	burger.addEventListener('click', () => {
		if (burger.classList.contains('active')) {
			burger.classList.remove('active')
			showMenu.classList.remove('show__burger-menu')
			body.style.overflowY = 'scroll'
		}
		else {
			burger.classList.add('active')
			showMenu.classList.add('show__burger-menu')
			body.style.overflowY = 'hidden'
		}
	})


	travel.addEventListener('click', () => {
		travelSub.classList.toggle('activeSubMenu')
		roomSub.classList.remove('activeSubMenu')
	})
	room.addEventListener('click', () => {
		roomSub.classList.toggle('activeSubMenu')
		travelSub.classList.remove('activeSubMenu')
	})
	showMenu.addEventListener('click', (e) => {
		if (e.target.tagName == "UL") {
			travelSub.classList.remove('activeSubMenu')
			roomSub.classList.remove('activeSubMenu')
		}
	})

}
module.exports = burgerMenu